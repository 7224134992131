import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="md:w-10/12">
                <h2>Tylenol Lawsuit Information</h2>
                <p>
                    Multiple studies have recently established a link between
                    acetaminophen use during pregnancy and an increased risk of
                    autism spectrum disorder (ASD) in children. This new
                    research contradicts previous findings that considered
                    acetaminophen-based products like Tylenol safe for use
                    during pregnancy. These studies specifically point to a
                    higher risk of ASD in children whose mothers consumed
                    Tylenol or acetaminophen while pregnant.
                </p>
            </div>
            <div className="relative md:ml-10 w-[248px] h-[158px] mb-10 md:mb-0">
                <Image
                    src="/tylenolautism/assets/images/tylenolAutismImg2.png"
                    alt="zantac"
                    layout="fill"
                />
            </div>
        </div>
    );
}
